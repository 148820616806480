<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="LevelDialogTitle"></span>
    </div>
    <!-- levelData： {{levelData}} -->
    <el-select v-model="levelValue" placeholder="请选择">
      <el-option
        v-for="item in LevelOptions"
        :key="item.id"
        :label="item.title"
        :value="item.id">
      </el-option>
    </el-select>
    <el-date-picker
      class="ml-15"
      v-model="dateValue"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择生效日期（含当日）">
    </el-date-picker>

    <el-card class="mt-20">
      <div slot="header" class="clearfix">
        <span>历史记录</span>
      </div>
      <el-table :data="dataList" border fit highlight-current-row>
        <el-table-column align="center" label="级别" prop="teacherLevel.title"></el-table-column>
        <el-table-column align="center" label="生效日期" prop="effectiveDate"></el-table-column>
      </el-table>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination> -->
    </el-card>

    <el-row slot="footer">
      <el-button type="primary" @click="saveLevelForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListLevel, getListTeacherLevelLog, setTeacherLeve } from '@/api/system/level'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    levelData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
         return time.getTime() < Date.now() - 8.64e6
        // return time.getTime() < Date.now-8.64e7   //如果当天可选，就不用减8.64e7
        }
      },
      LevelDialogTitle: '',
      dateValue: '',
      levelValue: '',
      LevelOptions: [],
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  methods: {
    openDialog () {
      this.LevelDialogTitle = '教师级别编辑'
      this.levelValue = Boolean(this.levelData.levelInfo) ? this.levelData.levelInfo.levelId : ''
      this.dateValue = Boolean(this.levelData.levelInfo) ? this.levelData.levelInfo.effectiveDate : ''
      // if (this.levelData === null) {
      //   this.LevelDialogTitle = '教师级别新增'
      // } else {
      //   this.LevelDialogTitle = '教师级别编辑'
      // }
      this.getListLevel()
      this.getListTeacherLevelLog()
    },

    async getListLevel () {
      const res = await getListLevel()
      this.LevelOptions = res.body
    },

    async getListTeacherLevelLog () {
      const res = await getListTeacherLevelLog({
        userId: this.levelData.userId
      })
      this.dataList = res.body
      // this.pagination.total = res.body.total
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListTeacherLevelLog()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListTeacherLevelLog()
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    async saveLevelForm () {
      if (!this.levelValue) {
        window.$msg('请选择级别', 2)
      } else {
        await setTeacherLeve({
          userId: this.levelData.userId,
          levelId: this.levelValue,
          effectiveDate: this.dateValue
        })
        window.$msg('编辑成功')
        this.close()
        this.$emit('updateView')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
</style>
