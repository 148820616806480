<template>
  <el-row class="table">
    <span class="title">教师级别管理</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入搜索用户名、姓名关键字"
        v-model.trim="inputVal"
        class="tb-search-input"
        @keyup.enter.native="search"
      >
        <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
          <el-option label="姓名" value="1"></el-option>
          <el-option label="用户名" value="2"></el-option>
        </el-select>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
    </el-row>

      <el-table :data="dataList" border fit highlight-current-row class="tb-list mt-15">
        <el-table-column align="center" label="工号" prop="loginName"></el-table-column>
        <el-table-column align="center" label="姓名" prop="name"></el-table-column>
        <el-table-column align="center" label="级别">
          <template slot-scope="scope">
            <span v-if="Boolean(scope.row.levelInfo)">{{scope.row.levelInfo.teacherLevel.title}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="edit(scope.row)"
            >编 辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

      <!--  -->
      <level-data
        :show="levelDlalog"
        :levelData="levelData"
        @close="levelDlalog=false;levelData=null"
        @updateView="getListTeacherLevel"
      ></level-data>
  </el-row>
</template>

<script>
import { getListTeacherLevel } from '@/api/system/level'
import levelData from '@/components/system/setLevel'

export default {
  components: {
    levelData
  },
  data () {
    return {
      levelDlalog: false,
      levelData: null,
      dataList: [],
      inputVal: '',
      select: '1',
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {
    this.getListTeacherLevel()
  },
  methods: {
    async getListTeacherLevel () {
      const res = await getListTeacherLevel({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        loginName: this.select === '2' ? this.inputVal : '',
        name: this.select === '1' ? this.inputVal : ''
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    search () {
      this.pagination.currentPage = 1
      this.getListTeacherLevel()
    },

    reset () {
      this.inputVal = ''
      this.select = '1'
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListTeacherLevel()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListTeacherLevel()
    },

    edit (row) {
      this.levelData = row
      this.levelDlalog = true
    }
  }
}
</script>

<style lang="scss" scope>
@import "../../style/table.scss";
</style>
