import $axios from '@/utils/request'

// 级别列表
export function getListLevel (data) {
  const url = '/user/listLevel'
  return $axios.fPost(url, data)
}

// 教师级别信息列表
export function getListTeacherLevel (data) {
  const url = '/user/listTeacherLevel'
  return $axios.fPost(url, data)
}

// 教师级别变更记录
export function getListTeacherLevelLog (data) {
  const url = '/user/listTeacherLevelLog'
  return $axios.fPost(url, data)
}

// 设置教师级别
export function setTeacherLeve (data) {
  const url = '/user/setTeacherLeve'
  return $axios.fPost(url, data)
}

// 撤销教师级别
export function delTeacherLevel (data) {
  const url = '/user/delTeacherLevel'
  return $axios.fPost(url, data)
}
